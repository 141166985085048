import React from 'react';
import styled from '@emotion/styled';
import { StaffPanel } from './Staff-Panel';
import { graphql, useStaticQuery } from 'gatsby';
// import { Link } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
// import { colors, device } from './layout/GlobalStyles';

const StyledOurTeamPanels = styled.div``;

const staff = [
    {
        name: 'Dr. Charles Turner, MD',
        title: 'MD',
        bio: 'Dr. Turner is board certified in family medicine, Anti-aging medicine, Obesity medicine and Addictions. After completing residency through the Medical College of Wisconsin, he worked in acute care and emergency medicine for the next four years. He spent a year doing primary care in a remote area of Tennessee before coming to Lafayette, IN in 1997. Dr. Turner is happily married with two grown children.',
        image: `Turner-pic`,
    },
    {
        name: 'Jill J. Pease',
        title: 'Physician Assitant',
        bio: 'Bio coming soon!',
        image: `Placeholder`,
    },
    {
        name: 'Jeff Lane',
        title: 'Nurse Practitioner',
        bio: 'Bio coming soon!',
        image: `Lane`,
    },
    {
        name: 'Susan',
        title: 'IV Therapist',
        bio: 'Susan works with our I.V. patents she is very soft spoken and enjoys life. Susan has a great personality and is always wanting the best for our I.V. patents. Susan has been with Innovative Medicine for 5+ years.',
        image: `Placeholder`,
    },
    {
        name: 'Kassie Laflen',
        title: 'Certified Medical Assistant',
        bio: 'Kassie has been employed at Innovative Medicine for 2 years. She is a graduate of Harrison College and helps with taking care of patients. Kassie is happily married and has a 4 year old son, and has two step children.',
        image: `laflen`,
    },
    {
        name: 'Carol',
        title: 'Weight Loss',
        bio: 'Bio coming soon!',
        image: `Placeholder`,
    },
    {
        name: 'Tracy',
        title: 'Weight Loss',
        bio: 'Bio coming soon!',
        image: `Placeholder`,
    },
    {
        name: 'Missy Galbreth',
        title: 'LMHC – Therapist',
        bio: 'She earned her BS in psychology from IU and her MS in agency counseling from ISU. Missy has been a clinician for nearly 20 years and has been at Innovative Medicine since April 2013. She has worked in outpatient settings, inpatient settings, residential and day treatment programs, schools, and in homes. Her counseling experience has been with all ages from 3 years old up. Missy has conducted thousands of group therapy sessions, individual, couples, and family therapy sessions. Although Missy has had experience with many mental health and behavioral issues, her most recent focus has been on addiction, anxiety, depression, abuse, trauma, and neglect, as well as how they can be related.',
        image: `galbreth`,
    },
];

//you will have to make a folder of all staff members.
// make a static query for all images then match it to the image key value

export const OurTeamPanels: React.FC = () => {
    const {
        allFile: { edges },
    } = useStaticQuery(graphql`
        query MyQuery {
            allFile(filter: { relativeDirectory: { eq: "team" } }) {
                edges {
                    node {
                        id
                        childImageSharp {
                            gatsbyImageData(placeholder: NONE, quality: 100)
                        }
                        name
                    }
                }
            }
        }
    `);

    return (
        <StyledOurTeamPanels className="small-container">
            <p>
                Our staff members are caring and are trained to assist in delivering the highest
                quality of service. They work hard to assure individual attention and an enjoyable
                office visit.
            </p>
            {staff.map(person => {
                return (
                    <StaffPanel
                        key={person.name}
                        bio={person.bio}
                        name={person.name}
                        title={person.title}
                    >
                        {edges.map(
                            (image: {
                                node: {
                                    name: string;
                                    childImageSharp: { gatsbyImageData: IGatsbyImageData };
                                };
                            }) =>
                                image.node.name === person.image.toLowerCase() && (
                                    <GatsbyImage
                                        alt={image.node.name}
                                        image={image.node.childImageSharp.gatsbyImageData}
                                    />
                                )
                        )}
                    </StaffPanel>
                );
            })}
        </StyledOurTeamPanels>
    );
};

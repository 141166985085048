import { DefaultLayout } from '../../layouts/DefaultLayout';
import { Hero } from '../../components/Hero';
import { OurTeamPanels } from '../../components/Our-Team-Panels';
import { StaticImage } from 'gatsby-plugin-image';
import { Seo } from '../../components/seo';

const breadCrumbNames = ['About Us', 'Meet Our Team'];
const breadCrumbUrls = ['/about-us/', '/about-us/meet-our-team/'];

const MeetOurTeam: Page = () => {
    return (
        <>
            <Seo
                title="Meet Our Staff Lafayette, IN - Innovative Medicine"
                description="Innovative Medicine staff members are caring and are trained to assist in delivering the highest quality of service. They are very soft spoken and experienced."
            />
            <Hero
                title="Meet our Team"
                breadCrumbUrls={breadCrumbUrls}
                breadCrumbNames={breadCrumbNames}
            >
                {/* <StaticImage
                    quality={100}
                    placeholder="none"
                    src="../../images/meet-our-team-wide.jpeg"
                    alt="Innovative Medicine Team"
                /> */}
            </Hero>
            <OurTeamPanels />
        </>
    );
};
MeetOurTeam.Layout = DefaultLayout;

export default MeetOurTeam;

import React from 'react';
import styled from '@emotion/styled';
// import { Link } from 'gatsby';
// import { StaticImage } from 'gatsby-plugin-image';
import { colors, device } from './layout/GlobalStyles';

const StyledStaffPanel = styled.div`
    margin-bottom: 32px;

    div:first-of-type {
        /* width: 350px;
        height: 408px; */
        border-radius: 15px;
        margin: 0;

        img {
            margin: 44px 0px 23px;
            border-radius: 15px;
            width: 343px;
            height: 408px;
        }
    }
    h3 {
        margin: 0 0 10px 0px;
        font-weight: 600;
        font-size: 28px;
        line-height: 42px;
        color: ${colors.blue2};
    }
    span {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 132.5%;
        color: ${colors.gray4};
    }
    p {
        font-weight: 400;
        font-size: 16px;
        margin-top: 16px;

        line-height: 161.5%;
    }
    @media ${device.tablet} {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        .text-container {
            width: 333px;
            text-align: center;
        }
    }
    @media ${device.laptop} {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 25px;
        margin-top: 44px;
        border-top: 1px solid grey;
        border-top: 1px solid grey;
        padding: 84px 0px;

        .text-container {
            text-align: left;
            width: 533px;
        }
        div:first-of-type {
            width: 399px;
            height: 491px;
            img {
                width: 399px;
                height: 491px;
            }
        }
    }
`;

interface Props {
    name: string;
    title: string;
    bio: string;
}

export const StaffPanel: React.FC<Props> = ({ name, title, bio, children }) => {
    return (
        <StyledStaffPanel className="container">
            {children}
            <div className="text-container">
                <h3>{name}</h3>
                <span>{title}</span>
                <p>{bio}</p>
            </div>
        </StyledStaffPanel>
    );
};
